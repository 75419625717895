.navbar {
    flex-wrap: wrap;
}

.navbar .nav-item {
    margin-bottom: -1px;
    flex: 1 1 auto;
}

.navbar .nav-item .nav-link {
    @apply inline-block px-2 py-2 w-full text-center text-grey sm:py-3 lg:px-0 lg:py-4 xl:px-6;
}

.navbar .nav-item .nav-link.active {
    @apply text-primary border-b-2 border-primary;
}

@media (max-width: 1280px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 640px) {
    .navbar .nav-item {
        min-width: auto;
    }
}
