@import './assets/styles/base.css';
@import './assets/styles/font-face.css';
@import './assets/styles/header.css';

html {
  overflow-y: scroll;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  /* select {
    @apply px-5 py-2 text-gray-800 placeholder-gray-400 border border-gray-300 rounded-md font-primary hover:border hover:border-primary focus:border focus:border-primary disabled:border-gray-300;
  } */

  textarea {
    @apply overflow-hidden;
  }
}
