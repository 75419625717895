.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  border: 2;
  border-radius: 100px; /* 3em */
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.storybook-button--primary {
  color: white;
  background-color: #0080c8;
  border: solid 2px #0080c8;
}
.storybook-button--secondary {
  color: #0080c8;
  background-color: white;
  border: solid 2px #0080c8;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset; */
}
.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 10px 25px;
  height: 41px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
.storybook-button--disabled {
  opacity: 45%;
}
.storybook-button--label {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  border: 2;
  border-radius: 100px; /* 3em */
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
}
