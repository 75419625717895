table {
}
table th {
  vertical-align: top;
  padding: 0.5rem;
}
table td {
  height: 32px;
  font-weight: 300;
  padding: 0.25rem;
}
table td,
table th {
}
table td.firstCol,
table th.firstCol {
  padding-left: 1rem;
}
/* input { -webkit-tap-highlight-color: transparent; } */
